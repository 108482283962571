{
  "name": "esri-leaflet-gp",
  "description": "A Leaflet plugin for interacting with ArcGIS geoprocessing services.",
  "version": "2.0.1",
  "author": "John Gravois <jgravois@esri.com> (http://johngravois.com)",
  "browser": "dist/esri-leaflet-gp-debug.js",
  "bugs": {
    "url": "https://github.com/jgravois/esri-leaflet-gp/issues"
  },
  "contributors": [
    "John Gravois <jgravois@esri.com> (http://johngravois.com)",
    "Nicholas Furness <nfurness@esri.com> (http://nixta.github.io/)",
    "Patrick Arlt <parlt@esri.com> (http://patrickarlt.com)",
    "Rowan Winsemius"
  ],
  "dependencies": {
    "leaflet": "^1.0.0-rc.3",
    "esri-leaflet": "^2.0.0"
  },
  "devDependencies": {
    "chai": "2.3.0",
    "gh-release": "^2.0.0",
    "highlight.js": "^8.0.0",
    "http-server": "^0.8.5",
    "isparta": "^3.0.3",
    "istanbul": "^0.4.2",
    "karma": "^0.12.24",
    "karma-chai-sinon": "^0.1.3",
    "karma-coverage": "^0.5.3",
    "karma-mocha": "^0.1.0",
    "karma-mocha-reporter": "^0.2.5",
    "karma-phantomjs-launcher": "^0.2.0",
    "karma-sourcemap-loader": "^0.3.5",
    "mkdirp": "^0.5.1",
    "phantomjs": "^1.9.17",
    "rollup": "^0.25.4",
    "rollup-plugin-json": "^2.0.0",
    "rollup-plugin-node-resolve": "^1.4.0",
    "rollup-plugin-uglify": "^0.1.0",
    "semistandard": "^7.0.5",
    "sinon": "^1.11.1",
    "sinon-chai": "2.7.0",
    "snazzy": "^2.0.1",
    "uglify-js": "^2.6.1",
    "watch": "^0.17.1"
  },
  "homepage": "https://github.com/jgravois/esri-leaflet-gp",
  "jsnext:main": "src/EsriLeafletGP.js",
  "jspm": {
    "registry": "npm",
    "format": "es6",
    "main": "src/EsriLeafletGP.js"
  },
  "license": "Apache-2.0",
  "main": "dist/esri-leaflet-gp-debug.js",
  "readmeFilename": "README.md",
  "repository": {
    "type": "git",
    "url": "git@github.com:jgravois/esri-leaflet-gp.git"
  },
  "scripts": {
    "prebuild": "mkdirp dist",
    "build": "rollup -c profiles/debug.js & rollup -c profiles/production.js",
    "lint": "semistandard src/**/*.js | snazzy",
    "prepublish": "npm run build",
    "pretest": "npm run build",
    "test": "npm run lint && karma start",
    "release": "./scripts/release.sh",
    "start": "watch 'npm run build' src & http-server -p 5000 -c-1 -o"
  }
}
